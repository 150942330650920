import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private api:ApiService, private router:Router) { }

  canActivate(x) {
    // console.log("cana x:",x, " ilo:", this.api.isLogged);
    if (!this.api.isLogged) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }

  canDeactivate(x){ // Cancel navigation
    //console.log("canDE="+this.api.isLogged);
    // if (this.api.isLogged()) {
    //   return false;
    // }
    return true;
  }
}
