import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../helpers/custom-validators';
import { switchMap } from "rxjs/operators" // RxJS v6
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from '../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-page-password-reset',
  templateUrl: './page-password-reset.component.html',
  styleUrls: ['./page-password-reset.component.scss']
})
export class PagePasswordResetComponent implements OnInit {

  public frmSignup: FormGroup;
  isLoading=false;
  error=null;


  constructor(private api: ApiService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router:Router, private spinner:NgxSpinnerService) {
    //this.frmSignup = this.createSignupForm();
    this.frmSignup = this.fb.group({
        pass: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.Pattern(/\d/, { hasNumber: true }),
            CustomValidators.Pattern(/[A-Z]/, { hasCapitalCase: true }),
            CustomValidators.Pattern(/[a-z]/, { hasSmallCase: true }),
            CustomValidators.Pattern( /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }
          ),
            Validators.minLength(8)
          ])
        ],
        passConfirm: [null, Validators.compose([Validators.required])],
        token: [null, Validators.required]
      }, {
        validator: CustomValidators.MustMatch('pass', 'passConfirm')
      }
    );

    this.activatedRoute.paramMap.subscribe(params => {
      this.frmSignup.controls['token'].setValue(params.get('token'));
    });
  }

  ngOnInit() {

  }

  onSubmit(data){
    if(!this.isLoading){
      this.isLoading=true;
      delete data.passConfirm;
      this.spinner.show();
      this.api.confirmRecoveryPassword(data).then((res:any) => {
        this.spinner.hide();
        this.isLoading=false;
          this.router.navigateByUrl('/login');
        }).catch(e=>{
          this.spinner.hide();
          this.isLoading=false;
          this.error=e;
      });
    }
  }

}
