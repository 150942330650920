import { ValidationErrors, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export class CustomValidators {
  static Pattern(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) { // if control is empty return no error
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  // static passwordMatchValidator(control: AbstractControl) {
  //   const password: string = control.get('pass').value; // get password from our password form control
  //   const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
  //   // compare is the password math
  //   if (password !== confirmPassword) {
  //     // if they don't match, set an error in our confirmPassword form control
  //     control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
  //   }
  // }

  // custom validator to check that two fields match
  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) { // return if another validator has already found an error on the matchingControl
            return;
        }
        if (control.value !== matchingControl.value) {// set error on matchingControl if validation fails
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

}
