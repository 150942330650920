import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageCardsComponent } from './page-cards/page-cards.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { PageFaqComponent } from './page-faq/page-faq.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

//Material
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { NgSelectModule } from '@ng-select/ng-select';
import { HelpBoxComponent } from './help-box/help-box.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageAccountComponent } from './page-account/page-account.component';
import { BannerRightComponent } from './banner-right/banner-right.component';
import { PageRegisterADataComponent } from './page-register-a-data/page-register-a-data.component';
import { PageRegisterBEmailComponent } from './page-register-b-email/page-register-b-email.component';
import { PageRegisterCPasswordComponent } from './page-register-c-password/page-register-c-password.component';
import { PagePasswordResetComponent } from './page-password-reset/page-password-reset.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { FooterComponent } from './footer/footer.component';
import { PageContactComponent } from './page-contact/page-contact.component';

//import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';
import { environment as env } from '../environments/environment';
import { ComponentNameComponent } from './component-name/component-name.component';
import { PagePrivacidadComponent } from './page-privacidad/page-privacidad.component';
import { PageLegalComponent } from './page-legal/page-legal.component';
import { PageCookiesComponent } from './page-cookies/page-cookies.component';
import { PageAtencionComponent } from './page-atencion/page-atencion.component';
import { PageContratoComponent } from './page-contrato/page-contrato.component';

import { NgxIntlTelInputModule } from 'projects/ngx-intl-tel-input/src/lib/ngx-intl-tel-input.module';
import { BsDropdownModule } from 'ngx-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { PageTermsConditionsComponent } from './page-terms-conditions/page-terms-conditions.component';

@NgModule({
  declarations: [
    AppComponent,
    PageCardsComponent,
    NavigationComponent,
    PageFaqComponent,
    HelpBoxComponent,
    PageAccountComponent,
    BannerRightComponent,
    PageRegisterADataComponent,
    PageRegisterBEmailComponent,
    PageRegisterCPasswordComponent,
    PagePasswordResetComponent,
    PageLoginComponent,
    PageHomeComponent,
    FooterComponent,
    PageContactComponent,
    ComponentNameComponent,
    PagePrivacidadComponent,
    PageLegalComponent,
    PageCookiesComponent,
    PageAtencionComponent,
    PageContratoComponent,
    PageTermsConditionsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Aceptar',
      clearLabel: 'Cancelar',
    }),
    NgSelectModule,
    NgbModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    //RecaptchaV3Module, //V3
    RecaptchaModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    NgxSpinnerModule,
    //Material
    MatDatepickerModule,
    MatNativeDateModule,
    NgcCookieConsentModule.forRoot(env.cookie_config),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: LOCALE_ID, useValue: 'es-ES' },
    //{ provide: RECAPTCHA_V3_SITE_KEY, useValue: env.recaptcha_key },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
