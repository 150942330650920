import { Injectable } from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { Router } from '@angular/router';
import { Breakpoints } from '@angular/cdk/layout';
import { GlobalResponse, MovementsResponse } from '../models/api.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'Access-Control-Allow-Origin': '*',
    'x-api-key':'3',
    // 'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public apiURL: string;// = 'https://apisandbox.aganeapay.com/cards';
  public isLogged:boolean=false;
  public auth=null;


  constructor(private http: HttpClient, private router:Router) {
    this.apiURL = env.api_url;
    let auth = localStorage.getItem('auth');
    if(auth && auth!=='undefined'){
      this.auth = JSON.parse(auth);
      // console.log(this.auth);
      this.updateIsLogged();
    }
  }

  updateIsLogged(){
    if(this.auth && this.auth.auth_token && this.auth.auth_token!==''){
      //let date = new Date(this.auth.valid_till*1000);
      this.isLogged=true;
      return;
    }
    this.isLogged=false;
  }


  logout(){
    this.auth=null;
    this.isLogged=false;
    localStorage.clear();
  }
  //******* POST *******/
  async login(data){
    let json = JSON.stringify(data);
    let self = this;
    let httpOptions = { headers: new HttpHeaders({'Content-Type':'application/json', 'x-api-key':'3'})};
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/auth', json, httpOptions).subscribe({
        next(response){
          if(response){
            self.auth=response;
            localStorage.setItem('auth', JSON.stringify(self.auth));
            self.updateIsLogged();
          }
          resolve(response);
          // localStorage.setItem('user',signupResponse.user);
          // localStorage.setItem('role',signupResponse.role);
          // localStorage.setItem('auth_token',signupResponse.auth_token);
          // localStorage.setItem('valid_till',signupResponse.valid_till);

        }, error(msg){
          reject(self.handleError(msg));
        }
      });
    });

    return true;
  }

  async register(data){
    let self=this;
    let json = JSON.stringify(data);
    let httpOptions =  { headers: new HttpHeaders({ 'Content-Type':'application/json', 'x-api-key':'3' })};

    return new Promise((resolve, reject)=>{
      this.http.post(env.api_url+'/register', json, httpOptions).subscribe({
        next(response){
            //console.log(response);
            resolve(response);
          }, error(error:HttpErrorResponse){
            reject(self.handleError(error));
          }
        });
    });
  }

  async confirmRegister(data){
    let self=this;
    let token = data.token;
    delete data.token;
    let json = JSON.stringify(data);
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/confirm/'+token, json, httpOptions).subscribe({
        next(response){
            //console.log(response);
            resolve();
          }, error(e){
            console.error(e);
            reject(self.handleError(e));
          }
        });
    });
  }

  changePassword(data){
    let self=this;
    let json = JSON.stringify(data);
    return new Promise((resolve, reject)=>{
      this.http.post(env.api_url+'/changePassword', json, this.getHttpOptions(true)).subscribe({
        next(response){
            // console.log(response);
            resolve(response);
          }, error(e){
            reject(self.handleError(e))
          }
        });
    });
  }

  recoveryPassword(data){
    let self = this;
    let json = JSON.stringify(data);
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/recoveryPassword', JSON.stringify(data), this.getHttpOptions()).subscribe({
        next(response){
            console.log(response);
            resolve(response);
          }, error(e){
            reject(self.handleError(e));
          }
        });
    });
  }
  confirmRecoveryPassword(data){
    let self=this;
    let token = data.token;
    delete data.token;
    let json = JSON.stringify(data);
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/confirmRecoveryPassword/'+token, json, this.getHttpOptions()).subscribe({
        next(response){
            // console.log(response);
            resolve(response);
          }, error(e){
            reject(self.handleError(e));
          }
        });
    });
  }





  //******* PUT *******/
  public unblockCard(card){
    let self=this;
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/cards/'+card+'/unblock', '', this.getHttpOptions(true)).subscribe({
        next(response){
            resolve(response);
          }, error(e){
            self.handleError(e);
            reject(e);
          }
        });
    });
  }
  public blockCard(card){
    let self=this;
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/cards/'+card+'/block', '', this.getHttpOptions(true)).subscribe({
        next(response){
            resolve(response);
          }, error(e){
            self.handleError(e);
            reject(e);
          }
        });
    });
  }
  public deactivateCard(card){
    let self=this;
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/cards/'+card+'/deactivate', '', this.getHttpOptions(true)).subscribe({
        next(response){
            resolve(response);
          }, error(e){
            self.handleError(e);
            reject(e);
          }
        });
    });
  }
  // public getCardPIN(card){
  //   let self=this;
  //   return new Promise((resolve, reject)=>{
  //     this.http.put(this.apiURL+'/cards/'+card+'/activate', '', this.getHttpOptions(true)).subscribe({
  //       next(response){
  //           resolve(response);
  //         }, error(e){
  //           self.handleError(e);
  //           reject(e);
  //         }
  //       });
  //   });
  // }






  //******* GET *******/
  async getGlobal(){
    let self=this;
    return new Promise((resolve, reject)=>{
      if(!this.isLogged){ reject("Not logged"); }
      this.http.get(this.apiURL+'/global', this.getHttpOptions(true)).subscribe({
        next(response){
            resolve(response);
          }, error(e){
            self.handleError(e);
            reject(e);
          }
        });
    });
  }

  async movements(productRef, clientServiceId, cardId, params=null){ // Anterior a 2020-04
    let self=this;
    return new Promise((resolve, reject)=>{
      if(!this.isLogged){ reject("Not logged"); }
      let httpOptions = this.getHttpOptions(true);
      if(params){
        httpOptions['params']=params;
      }
      this.http.get(this.apiURL+'/cards/'+productRef+'/'+clientServiceId+'/'+cardId+'/movements', httpOptions).subscribe({
        next(response){
            // console.log("movements:",response);
            resolve(response);
          }, error(e){
            self.handleError(e);
            //console.error(e);
            reject(e);
          }
        });
    });
    return;
  }

  async allMovements(gp:GlobalResponse, cardId, params=null){ // 2020-04
    return new Promise((resolve, reject)=>{
      if(!this.isLogged){ reject("Not logged"); }
      let httpOptions = this.getHttpOptions(true);
      if(params){
        httpOptions['params']=params;
      }
      this.http.get(this.apiURL+'/cards/'+gp.productRef+'/'+gp.clientServiceId+'/'+gp.id+'/'+cardId+'/allMovements', httpOptions).subscribe(
        (response:MovementsResponse)=>{
            // console.log("movements:",response);
            resolve(response);
        }, (e)=>{
            this.handleError(e);
            reject(e);
        });
    });
    return;
  }

  async getBalance(clientServiceId, cardId){
    let self=this;
    let url = this.apiURL+'/cards/'+clientServiceId+'/'+cardId+'/balance';
    return new Promise((resolve, reject)=>{
      if(!this.isLogged){ reject("Not logged"); }
      this.http.get(url, this.getHttpOptions(true)).subscribe({
        next(response){
            // console.log("balance:",response);
            resolve(response);
          }, error(e){
            self.handleError(e);
            // console.error("balance error:",e);
            reject(e);
          }
        });
    });
  }

  newCard(data){
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/newCardRequest', JSON.stringify(data), this.getHttpOptions(false)).subscribe({
        next(response){
            // console.log("OK",response);
            resolve(response);
          }, error(msg){
            // console.error("Error",msg);
            reject(msg);
          }
        });
    });
  }

  contactForm(data){
    let self=this;
    return new Promise((resolve, reject)=>{
      if(!this.isLogged){
        reject("Not logged");
      }
      this.http.post(this.apiURL+'/contactForm', JSON.stringify(data), this.getHttpOptions(true)).subscribe({
        next(response){
            console.log(response);
            resolve(response);
          }, error(e){
            reject(self.handleError(e));
          }
        });
    });
  }

  getRoadTypes(){
    return new Promise((resolve, reject)=>{
      this.http.get(this.apiURL+'/road_types', this.getHttpOptions()).subscribe({
        next(response){
            resolve(response);
          }, error(msg){
            //console.error(msg);
            reject(msg);
          }
        });
    });
  }
  getProvinces(){
    return new Promise((resolve, reject)=>{
      this.http.get(this.apiURL+'/provinces', this.getHttpOptions()).subscribe({
        next(response){
            resolve(response);
          }, error(msg){
            //console.error(msg);
            reject(msg);
          }
        });
    });
  }

  public pinRequest(cardId, accountCardIdentificationCode, mobilePhone){
    let self=this;
    let body = JSON.stringify({cardId:cardId, accountCardIdentificationCode:accountCardIdentificationCode, mobilePhone:mobilePhone});
    // console.log("prr:",body);
    return new Promise((resolve, reject)=>{
      this.http.post(this.apiURL+'/cards/pinRequest', body, this.getHttpOptions(true)).subscribe({
        next(response:any){
          if(response.responseCode==="409"){
            reject('Se ha producido un error al solicitar el pin');
            return;
          }else{
            resolve(response);
          }
        }, error(e){
          if(e.status==409){
            reject('Se ha producido un error al solicitar el pin');
            return;
          }else{
            reject(self.handleError(e));
          }
        }
      });
    });
  }

  getUserInformation(){
    let self=this;
    return new Promise((resolve, reject)=>{
      this.http.get(this.apiURL+'/informationUser', this.getHttpOptions(true)).subscribe({
        next(response){
            resolve(response);
          }, error(e){
            self.handleError(e);
            reject(e);
          }
        });
    });
  }
  updateUserInformation(data){
    let self=this;
    let json = JSON.stringify(data);

    return new Promise((resolve, reject)=>{
      this.http.post(env.api_url+'/updateInformationUser', json, this.getHttpOptions(true)).subscribe({
        next(response){
            console.log(response);
            resolve(response);
          }, error(error:HttpErrorResponse){
            reject(self.handleError(error));
          }
        });
    });
  }



  // recaptchaVerify(token){
  //   let self=this;
  //   let json = JSON.stringify({secret:env.recaptcha_key, response:token});

  //   return new Promise((resolve, reject)=>{
  //     this.http.post('https://www.google.com/recaptcha/api/siteverify', json, this.getHttpOptions()).subscribe({
  //       next(response){
  //           console.log("recaptcha:",response);
  //           resolve(response);
  //         }, error(error:HttpErrorResponse){
  //           reject(self.handleError(error));
  //         }
  //       });
  //   });
  // }



  /** HttpOptions */
  getHttpOptions(withToken=false){
    if(withToken){
      return { headers: new HttpHeaders({
        'Content-Type':'application/json',
        'x-api-key':'3',
        'Authorization':this.auth.auth_token
      })};
    }else{
      return { headers: new HttpHeaders({
        'Content-Type':'application/json',
        'x-api-key':'3',
      })};
    }
  }


  public hc(token){
    //Headers
    httpOptions.headers = httpOptions.headers.set('Authorization', token);

    return this.http.get(this.apiURL+'/global', httpOptions).pipe();
}



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return "Error de conexión.";
      return error.error.message;
    } else {
      if(error.status==401){//unauthorized
        this.logout();
        this.router.navigateByUrl('/login');
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was:`, error.error);
        switch(error.status){
          case 409:
            if(error.error.responseMessage==='User exist'){
              return "Este usuario ya existe, vaya al Acceso Area Cliente";
            }else{ // error.error.responseMessage ==="Data Conflict"
              return "Los datos introducidos son incorrectos";
            }
           break;
          case 404:
            if(error.error.responseMessage==='Data Conflict'){
              return "Los datos introducidos son incorrectos";
            }
            return "Se ha producido un error al realizar la operación.";
            break;
          case 401: return "Usuario o contraseña incorrectos."; break;
          case 400:
            if(error.error.responseMessage === "No passwords match")  {
              return "La antigua contraseña es incorrecta."; break;
            } else if(error.error.errors != "undefined" && error.error.errors.length > 0) {
              return "Se deben rellenar todos los campos."; break;
            } else {
              return "Se ha producido un error al realizar la operación."; break;
            }
          default: return "Se ha producido un error al realizar la operación."; break;
        }



        if(error.error.responseMessage != undefined){
          return error.error.responseMessage;
        }else{
          return error.error.message;
        }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
