import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-register-b-email',
  templateUrl: './page-register-b-email.component.html',
  styleUrls: ['./page-register-b-email.component.scss']
})
export class PageRegisterBEmailComponent implements OnInit {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private router:Router) {
    this.matIconRegistry.addSvgIcon(
      'message',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/message.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/check.svg')
    );

  }

  ngOnInit() {
  }

  goHome(){
    this.router.navigateByUrl('/login');
    // window.location.href = "/login";

  }

}
