import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, Injector } from '@angular/core';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'up-aganea';


  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(private ccService: NgcCookieConsentService, private translateService:TranslateService){
    translateService.setDefaultLang('es');
    translateService.use('es');
  }

  ngOnInit(){
    let c = this.getCookie('cookies-accepted');
    if(c){
      this.ccService.destroy();
    }else{
      this.translateService
        .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
        .subscribe(data => {
          // console.log("ts d:",data);
          this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
          // Override default messages with the translated ones
          this.ccService.getConfig().content.header = data['cookie.header'];
          this.ccService.getConfig().content.message = data['cookie.message'];
          this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
          this.ccService.getConfig().content.allow = data['cookie.allow'];
          this.ccService.getConfig().content.deny = data['cookie.deny'];
          this.ccService.getConfig().content.link = data['cookie.link'];
          this.ccService.getConfig().content.policy = data['cookie.policy'];

          this.ccService.destroy();//remove previous cookie bar (with default messages)
          this.ccService.init(this.ccService.getConfig()); // update config with translated messages
        });

      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        (e) => { // you can use this.ccService.getConfig() to do stuff...
          // console.log("pos:",e);
          let c = this.getCookie('cookies-accepted');
          // console.log("c:",c);
      });
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        (e) => { // you can use this.ccService.getConfig() to do stuff...
          // console.log("pcs:",e);
      });
      this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => { // you can use this.ccService.getConfig() to do stuff...
          // console.log("is:", event);
      });
      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => { // you can use this.ccService.getConfig() to do stuff...
          switch(event.status){
            case 'allow': this.setCookie('cookies-accepted', 'true', 30); break;
            case 'deny': break;
          }
          // console.log("scs:", event);
      });
      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => { // you can use this.ccService.getConfig() to do stuff...
          // console.log("rcs:", event);
      });
      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => { // you can use this.ccService.getConfig() to do stuff...
          // console.log("ncls:", event);
      });
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}
