import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-privacidad',
  templateUrl: './page-privacidad.component.html',
  styleUrls: ['./page-privacidad.component.scss']
})
export class PagePrivacidadComponent implements OnInit {

  private fragment: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.onResize({});
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  menuHeight=116;
  footerHeight=80;
  height=500;

  @HostListener('window:resize', ['$event'])
  onResize(event){
    //event.target.innerHeight
    this.height = (window.innerHeight-this.menuHeight-this.footerHeight);
  }


}
