export interface LoginResponse{
  user:string,
  role:string,
  last_login:Date,
  auth_token:string,
  valid_till:string, // Integer string
}
export interface User{
  name:string,
  nif:string,
  mobile:string,
  email:string,
  address:string,
  postal_code:string,
  password:string,
  password2:string,
};
export interface UserInformation{
  idNumberDoc:string
  emailAddress:string
  mobilePhone:string
  firstName:string
  lastName:string
  secondLastName:string
  birthDate:string
  typeRoad:string
  address:string
  zipCode:string
  city:string
  province:string
}
export interface GlobalResponse{
  encodedKey:string,
  id:string, // Bank account
  balance:string, // 25.25
  name:string, //Card Account
  clientServiceId:string, // Different Bank Account
  productRef:string, // PRDCSANTREGA
  cards:Card[],
}
export interface Card{
  cardId:string,
  status:string, // ORDERABLE, ACTIVE, CANCELLED,
  last4:string,
  expiryDate:string,
  accountCardIdentificationCode:string,
}
export interface MovementOld{
  beneficiaryServiceId:string,
  cardId:string,
  mccCode:string,
  returnCode:string,
  dateTransaction:Date,
  clientServiceId:string,
  commerceName:string,
  authorizationNumber:null,
  typeTransaction:string,
  solde:string,
  accountCategory:string,
  actualAmount:string,
  balance:string
}
export interface Movement{
  balance:string,
  dateTransaction:Date,
  commerceName:string,
  typeTransaction:string,
}
export enum TypeTransaction{
  RETURN='0400-000000',
  CHARGE='0100-000000',
  DEPOSIT='DEPOSIT',
}
export interface MovementsResponse{
  list:Movement[],
  nbTotalResults:number,
  maxResult:number,
  startNumber:number,
}
