import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm, FormControl } from '@angular/forms';
import { CustomValidators } from '../helpers/custom-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';


import * as place from './mock.json';

declare var google:any;


@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit, AfterViewInit {

  @ViewChild('modal_completed', {static:true}) modal_completed:ElementRef;
  @ViewChild('places_autocomplete', {static:false}) places_autocomplete_element:ElementRef;

  subpage='home'; // home, solicitar, formulario
  sendForm:FormGroup;
  frontDNI:string=null;
  backDNI:string=null;
  isLoading=false;
  provinces=null;
  roadTypes=null;

  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
  inputValue: string;
  errorText=null;
  public recaptcha_key=env.recaptcha_key;
  canRequestCard=false;
  stateOfAlarm=false; // Estado de alarma

  places_autocomplete;

  constructor(private api:ApiService, private formBuilder:FormBuilder, private route:ActivatedRoute, private modalService: NgbModal, private router:Router, private spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.getProvinces();
    this.getRoadTypes();

    this.sendForm = this.formBuilder.group({
      'idSocio':[ null ], //, [Validators.required, Validators.minLength(4), Validators.maxLength(5)]],
      'name':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'lastName1':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'lastName2':[ null, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')],
      'idNumberDoc':[ null, [Validators.required, Validators.pattern('(^[0-9]{8}[a-zA-Z]$)|(^[a-zA-Z][0-9]{7}[a-zA-Z]$)')] ], //DNI o NIE
      'email':[ null, [Validators.email, Validators.required]],
      'email_confirm':[ null, [Validators.email, Validators.required]],
      // 'typeRoad':[ "", Validators.required],
      'address':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\- ]+$')]],
      'number':[ null, [Validators.maxLength(10), Validators.required]],
      'floor':[ null, Validators.maxLength(10) ],
      'door':[ null, Validators.maxLength(10) ],
      'zipCode':[ null, [Validators.required, Validators.minLength(3), Validators.maxLength(10)]],
      'city':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-.0-9\'· ]+$')]],
      'province':[ "", [Validators.required, Validators.minLength(1)]],

      'frontDNI':[null, Validators.required],
      'conditions':[null, Validators.requiredTrue],
      'recaptcha':[null, Validators.requiredTrue ],
      'backDNI':[null, Validators.required],
      'mobilePhone':[null, [Validators.required, Validators.minLength(6), Validators.maxLength(12),Validators.pattern('^[0-9]+$')] ]
    }, {
      validator: CustomValidators.MustMatch('email', 'email_confirm')
    });

    this.route.paramMap.subscribe(params=>{
      let subpage=params.get('subpage');
      if(subpage){ this.subpage=subpage; }
    });

    if(!env.production && env.form_new_card){
      this.sendForm.patchValue(env.form_new_card);
    }

    let now=moment();
    let day = parseInt(now.format('D'));
    let month = parseInt(now.format('M'));
    // Solo se pueden solicitar tarjeta del 3 al 7 de cada mes excepto en agosto que no se puede
    if(day >= 3 && day <= 7 && month != 8) {
      this.canRequestCard=true;
    } else {
      this.canRequestCard=false;
    }

    // if(env.fechas_solicitud_tarjeta){
    //   let now=moment();
    //   console.log('Día:'+now.format('d'));
    //   env.fechas_solicitud_tarjeta.forEach(e=>{
    //     if(now.isBetween(e.inicio, e.fin, 'day', '[]')){
    //       this.canRequestCard=true;
    //     }
    //   });
    //   if(this.stateOfAlarm){
    //     this.canRequestCard=false;
    //   }
    // }
  }



  ngAfterViewInit(){
    // this.processPlace(place.default);
  }

  processPlace(place){
    // console.log("2:", place, place.address_components);
    let cp=null;
    place.address_components.forEach((e)=>{
      let type=e.types[0];
      switch(type){
        case 'route': this.sendForm.controls['address'].setValue(e.long_name); break;
        case 'locality': this.sendForm.controls['city'].setValue(e.long_name); break;
        case 'administrative_area_level_2': /* this.sendForm.controls['city'].setValue(e.long_name); */ break; // Ciudad
        case 'administrative_area_level_1': /* this.sendForm.controls['province'].setValue(e.long_name); */ break; // Comunidad autónoma
        case 'country': /* this.sendForm.controls['zipCode'].setValue(e.long_name); */ break;
        case 'postal_code': cp=e.long_name.substr(0,2); this.sendForm.controls['zipCode'].setValue(e.long_name); break;
        case 'street_number': this.sendForm.controls['number'].setValue(e.long_name); break;
      }
    });
    if(cp){
      this.sendForm.controls['province'].setValue(this.provinces[Number(cp)-1]);
    }
  }

  places_autocomplete_bind(){
    // console.log("pab:", this.places_autocomplete_element);
    if(this.places_autocomplete_element && !this.places_autocomplete){
      this.places_autocomplete=new google.maps.places.Autocomplete(this.places_autocomplete_element.nativeElement, {componentRestrictions:{country:'ES'}, types:['geocode']});
      google.maps.event.addListener(this.places_autocomplete, 'place_changed', (d)=>{
        let place = this.places_autocomplete.getPlace();
        this.processPlace(place);
      });
    }
  }


  showCode(){
    const inputElement: HTMLInputElement = document.getElementById('phone') as HTMLInputElement;
    this.inputValue = inputElement.placeholder;
    this.inputValue = this.inputValue.substr(0, this.inputValue.indexOf(' '));
  }

  clickSolicitar(){
    //this.subpage='solicitar';
    this.router.navigateByUrl('/inicio/solicitar');

  }
  clickFormulario(){
    //this.subpage='formulario';
    this.router.navigateByUrl('/inicio/formulario');
    const inputElement: HTMLInputElement = document.getElementById('flag-picker') as HTMLInputElement;
    if(inputElement) inputElement.click();
  }
  clickBack(){
    switch(this.subpage){
      case 'formulario':
        // this.subpage='solicitar';
        this.router.navigateByUrl('/inicio/solicitar');
        break;
      case 'solicitar':
        // this.subpage='home';
        this.router.navigateByUrl('/inicio/home');
        break;
    }
  }

  resolved(e){
    this.sendForm.controls['recaptcha'].setValue(true);
  }

  onSubmit(data:NgForm){
    if(!this.sendForm.valid){
      this.errorText="Debes rellenar todos los campos";
      this.sendForm.markAllAsTouched();
    }else{
      this.errorText=null;
      delete data['recaptcha'];
      //this.sendForm.controls['mobilePhone'].setValue(this.inputValue+this.sendForm.controls['mobilePhone'].value);
      // console.log(data); return;
      if(!this.isLoading){
        this.isLoading=true;
        data['frontDNI']=this.frontDNI;
        data['backDNI']=this.backDNI;

        this.spinner.show();
        this.api.newCard(data).then(data=>{
          this.spinner.hide();
          this.isLoading=false;
          this.subpage='home';
          this.modalService.open(this.modal_completed, {
            ariaLabelledBy:'modal-basic-title',
            backdropClass:'modal-help-backdrop',
            windowClass:'modal-secondary',
            centered:true}
          ).result.then((result) => {
          }, (reason) => {
          });
        }).catch(e=>{
          this.spinner.hide();
          this.isLoading=false;

        });
      }
    }
  }

  onPhoto(e){
    let allowedTypes=['image/png', 'image/jpeg', 'application/pdf'];
    let reader = new FileReader();
    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      if(!allowedTypes.includes(file.type)){
        if(e.target.id==='frontDNIF'){
          this.sendForm.controls['frontDNI'].setErrors({type:true});
        }else{
          this.sendForm.controls['backDNI'].setErrors({type:true});
        }
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        if(e.target.id==='frontDNIF'){
          this.frontDNI=reader.result as string;
          this.sendForm.controls['frontDNI'].setValue(file.name);
          this.sendForm.controls['frontDNI'].setErrors(null);
        }else{
          this.backDNI=reader.result as string;
          this.sendForm.controls['backDNI'].setValue(file.name);
          this.sendForm.controls['backDNI'].setErrors(null);
        }
      };
    }
  }




  //LAODERS
  getProvinces(){
    this.provinces = [
      "ÁLAVA",
      "ALBACETE",
      "ALICANTE/ALACANT",
      "ALMERÍA",
      "ASTURIAS",
      "ÁVILA",
      "BADAJOZ",
      "BARCELONA",
      "BURGOS",
      "CÁCERES",
      "CÁDIZ",
      "CANTABRIA",
      "CASTELLÓN/CASTELLÓ",
      "CIUDAD REAL",
      "CÓRDOBA",
      "CUENCA",
      "GIRONA",
      "LAS PALMAS",
      "GRANADA",
      "GUADALAJARA",
      "GUIPÚZCOA",
      "HUELVA",
      "HUESCA",
      "ILLES BALEARS",
      "JAÉN",
      "A CORUÑA",
      "LA RIOJA",
      "LEÓN",
      "LLEIDA",
      "LUGO",
      "MADRID",
      "MÁLAGA",
      "MURCIA",
      "NAVARRA",
      "OURENSE",
      "PALENCIA",
      "PONTEVEDRA",
      "SALAMANCA",
      "SEGOVIA",
      "SEVILLA",
      "SORIA",
      "TARRAGONA",
      "SANTA CRUZ DE TENERIFE",
      "TERUEL",
      "TOLEDO",
      "VALENCIA/VALÈNCIA",
      "VALLADOLID",
      "VIZCAYA",
      "ZAMORA",
      "ZARAGOZA",
      "CEUTA", // Es necesario que Ceuta y Melilla estén al final para que el código postal se corresponda.
      "MELILLA",
    ];
    /*
    this.api.getProvinces().then(d=>{
      console.log(d);
      this.provinces=d;
    }).catch(e=>{
    });
    */
  }
  getRoadTypes(){
    this.roadTypes = [
      "AVENIDA",
      "CALLE",
      "CALLEJÓN",
      "CAMINO",
      "CARRETERA",
      "GLORIETA",
      "PASAJE",
      "PASEO",
      "PLAZA",
      "POLIGONO",
      "RAMBLA",
      "RESIDENCIA",
      "RONDA",
      "TRAVESÍA",
      "URBANIZACIÓN",
      "VÍA"
    ];
    /*
    this.api.getRoadTypes().then(d=>{
      console.log(d);
      this.roadTypes=d;
    }).catch(e=>{
    });
    */
  }



}
