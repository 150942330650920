import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { environment as env } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.scss']
})
export class PageContactComponent implements OnInit {

  @ViewChild('modal_completed', {static:true}) modal_completed:ElementRef;

  sendForm:FormGroup;
  isLoading=false;
  isSent=false;
  resultText="";
  isError=false;
  public recaptcha_key=env.recaptcha_key;

  constructor(private api:ApiService, private formBuilder:FormBuilder, private modalService:NgbModal, private spinner:NgxSpinnerService) {

  }

  ngOnInit() {
    this.sendForm = this.formBuilder.group({
      'fullName':[null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'email':[null, [Validators.email, Validators.required]],
      'subject':[null, [ Validators.required, Validators.maxLength(40)] ],
      'message':[null, [ Validators.required, Validators.maxLength(300)] ],
      //'recaptcha':[null, Validators.requiredTrue]
    });

    if(!env.production && env.form_contact){
      this.sendForm.setValue(env.form_contact);
    }
  }

  resolved(e){
    this.sendForm.controls['recaptcha'].setValue(true);
  }

  onSubmit(data:NgForm){
    //delete data['recaptcha'];
    //let self=this;
    if(!this.isLoading){
      this.isLoading=true;
      this.resultText='';
      this.isError=false;
      data['business'] = '.';
      this.spinner.show();
      this.api.contactForm(data).then(data=>{
        this.spinner.hide();
        this.isLoading=false;
        this.isSent=true;
        this.sendForm.reset();
        this.modalService.open(this.modal_completed, {
          ariaLabelledBy:'modal-basic-title',
          backdropClass:'modal-help-backdrop',
          windowClass:'modal-primary',
          centered:true}
        ).result.then((result) => {
        }, (reason) => {
        });
      }).catch(e=>{
        this.spinner.hide();
        this.isLoading=false;
        this.isError=true;
        this.resultText=e;

        console.log(e);

      });
    }
  }

}
