import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CustomValidators } from '../helpers/custom-validators';
import { switchMap } from "rxjs/operators" // RxJS v6
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from '../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-page-register-c-password',
  templateUrl: './page-register-c-password.component.html',
  styleUrls: ['./page-register-c-password.component.scss']
})
export class PageRegisterCPasswordComponent implements OnInit {

  @ViewChild('modal_completed', {static:true}) modal_completed:ElementRef;

  public frmSignup: FormGroup;
  isLoading=false;
  error=null;
  //public token: String;

  constructor(private api: ApiService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router:Router, private modalService: NgbModal, private spinner:NgxSpinnerService) {
    //this.frmSignup = this.createSignupForm();
    this.frmSignup = this.fb.group({
        pass: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.Pattern(/\d/, { hasNumber: true }),
            CustomValidators.Pattern(/[A-Z]/, { hasCapitalCase: true }),
            CustomValidators.Pattern(/[a-z]/, { hasSmallCase: true }),
            CustomValidators.Pattern( /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }
          ),
            Validators.minLength(8)
          ])
        ],
        passConfirm: [null, Validators.compose([Validators.required])],
        token: [null, Validators.required]
      }, {
        validator: CustomValidators.MustMatch('pass', 'passConfirm')
      }
    );

    this.activatedRoute.paramMap.subscribe(params => {
      this.frmSignup.controls['token'].setValue(params.get('token'));
    });





  }

  ngOnInit() {
    // this.modalService.open(this.modal_completed, {
    //   ariaLabelledBy:'modal-basic-title',
    //   backdropClass:'modal-help-backdrop',
    //   windowClass:'modal-secondary',
    //   centered:true});
  }

  onSubmit(data){
    if(!this.isLoading){
      this.isLoading=true;
      delete data.passConfirm;
      this.spinner.show();
      this.api.confirmRegister(data).then((res:any) => {
        this.spinner.hide();
          this.isLoading=false;

          this.modalService.open(this.modal_completed, {
              ariaLabelledBy:'modal-basic-title',
              backdropClass:'modal-help-backdrop',
              windowClass:'modal-secondary',
              centered:true}
          ).result.then((result) => {
          }, (reason) => {
              this.router.navigateByUrl('/login');
          });
        }).catch(e=>{
          this.spinner.hide();
          this.isLoading=false;
          this.error=e;
      });
    }
  }

}
