import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../services/api.service';



@Component({
  selector: 'app-page-faq',
  templateUrl: './page-faq.component.html',
  styleUrls: ['./page-faq.component.scss']
})
export class PageFaqComponent implements OnInit {

  menuHeight=116;
  footerHeight=80;
  height=500;

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.height = (window.innerHeight-this.menuHeight-this.footerHeight);
  }

  constructor( public api: ApiService) { }

  ngOnInit() {
    this.onResize({});
  }

  goToInicio(e){
    e.preventDefault();
    if(this.api.isLogged){
      window.open('/posicion-global', '_blank');
    }else{
      window.open('/inicio', '_blank');
    }
  }


}
