import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { sha256, sha224 } from 'js-sha256';
import { Router } from '@angular/router';
import { environment as env } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {

  @ViewChild('modal_completed', {static:true}) modal_completed:ElementRef;

  public signupResponse;
  public isReset = false;
  public loginError = false;
  public loginEmpty = false;
  //public sha256 = require('js-sha256');
  public sendForm=null;
  private isLoading=false;
  public recaptcha_key=env.recaptcha_key;
  isError=false;
  resultText='';

  constructor(private api: ApiService, private router:Router, private formBuilder:FormBuilder, private modalService: NgbModal,  private spinner:NgxSpinnerService) {

  }

  ngOnInit() {

    this.sendForm = this.formBuilder.group({
      'username': [null, Validators.required],
      'password': [null, Validators.required],
      'recaptcha':[null, Validators.requiredTrue]
    });

    if(!env.production && env.form_login){
      this.sendForm.setValue(env.form_login);
    }
  }

  resolved(e){
    this.sendForm.controls['recaptcha'].setValue(true);
  }

  onSubmit(data){
    delete data['recaptcha'];
    // this.router.navigateByUrl('/restablecer-contrasena/sjdkaygksudj');
    // return;
    if(!this.isLoading){
      this.isError=false;
      this.resultText='';
      if(this.isReset){
        if(data.username && data.username!==''){
          this.isLoading=true;
          if(data.password){delete data.password;}
          this.spinner.show();
          this.api.recoveryPassword(data).then((d:any)=>{
            this.spinner.hide();
            this.isLoading=false;
            if(!env.production){
              this.router.navigateByUrl('/restablecer-contrasena/'+d.registerCode);
            }else{
              //this.router.navigateByUrl('registro/correo');
              this.modalService.open(this.modal_completed, {
                ariaLabelledBy:'modal-basic-title',
                backdropClass:'modal-help-backdrop',
                windowClass:'modal-secondary',
                centered:true}
              ).result.then((result) => {
              }, (reason) => {
              });
            }
          }).catch(e=>{
            this.spinner.hide();
            this.isLoading=false;
            this.isError=true;
            this.resultText=e;
          });
        }
      }else{
        if(this.sendForm.valid){
          this.isLoading=true;
          data['password']=sha256(data['password']);
          // this.loginError=false;
          // this.loginEmpty = false;
          this.spinner.show();
          this.api.login(data).then(res=>{
            this.spinner.hide();
            this.isLoading=false;
            this.router.navigateByUrl("/posicion-global");
          }).catch(e=>{
            this.spinner.hide();
            this.isLoading=false;
            this.isError=true;
            this.resultText=e;
          });
        }
      }
    }
  }

  clickBack(){
    if(this.isReset){
      this.isReset=false;
    }else{
      this.router.navigateByUrl('/inicio');
    }
  }

  solicitar(){
    this.router.navigateByUrl('/inicio/solicitar', );
  }





}
