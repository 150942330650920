import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import{ PageCardsComponent } from './page-cards/page-cards.component';
import{ PageFaqComponent } from './page-faq/page-faq.component';
import { PageAccountComponent } from './page-account/page-account.component';
import { PageRegisterADataComponent } from './page-register-a-data/page-register-a-data.component';
import { PageRegisterCPasswordComponent } from './page-register-c-password/page-register-c-password.component';
import { PageRegisterBEmailComponent } from './page-register-b-email/page-register-b-email.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PagePasswordResetComponent } from './page-password-reset/page-password-reset.component';
import { AuthGuardService } from './services/auth-guard.service';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageLegalComponent } from './page-legal/page-legal.component';
import { PageCookiesComponent } from './page-cookies/page-cookies.component';
import { PageAtencionComponent } from './page-atencion/page-atencion.component';
import { PagePrivacidadComponent } from './page-privacidad/page-privacidad.component';
import { PageContratoComponent } from './page-contrato/page-contrato.component';
import { PageTermsConditionsComponent } from './page-terms-conditions/page-terms-conditions.component';

const routes: Routes = [
  {path: '', redirectTo: '/inicio', pathMatch: 'full'},
  {path: 'inicio',                          component: PageHomeComponent,              canDeactivate:[AuthGuardService] },
  {path: 'inicio/:subpage',                 component: PageHomeComponent,              canDeactivate:[AuthGuardService] },
  {path: 'registro',                        component: PageRegisterADataComponent,     canDeactivate:[AuthGuardService] },
  {path: 'registro/correo',                 component: PageRegisterBEmailComponent,    canDeactivate:[AuthGuardService] },
  {path: 'registro/:token/contrasena',      component: PageRegisterCPasswordComponent, canDeactivate:[AuthGuardService] },
  {path: 'restablecer-contrasena/:token',   component: PagePasswordResetComponent,     },
  {path: 'login',                           component: PageLoginComponent,             },
  {path: 'legal',                           component: PageLegalComponent,             },
  {path: 'terminos-condiciones',            component: PageTermsConditionsComponent,   },
  {path: 'atencion-cliente',                component: PageAtencionComponent,          },
  {path: 'privacidad',                      component: PagePrivacidadComponent,        },
  {path: 'cookies',                         component: PageCookiesComponent,        },
  {path: 'contrato',                        component: PageContratoComponent,          },

  {path: 'contacto',                        component: PageContactComponent,           canActivate:[AuthGuardService]},
  {path: 'faq',                             component: PageFaqComponent },
  {path: 'posicion-global',                 component: PageCardsComponent,             canActivate:[AuthGuardService] },
  {path: 'account',                         component: PageAccountComponent,           canActivate:[AuthGuardService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
