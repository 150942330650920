import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-banner-right',
  templateUrl: './banner-right.component.html',
  styleUrls: ['./banner-right.component.scss'],
  host:{
    '[class.noLogged]':'!logged',
    '[class.logged]':'logged',
  }
})
export class BannerRightComponent implements OnInit {

  logged:boolean=false;

  constructor(private api:ApiService) {
    this.logged = this.api.isLogged;
  }

  ngOnInit() {
  }

}
