import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import moment from 'moment';
moment.locale('es');
import { GlobalResponse, Card, UserInformation, MovementsResponse, Movement, TypeTransaction } from '../models/api.model';


@Component({
  selector: 'app-page-cards',
  templateUrl: './page-cards.component.html',
  styleUrls: ['./page-cards.component.scss']
})
export class PageCardsComponent implements OnInit {

  @ViewChild('modal_cancelar', {static:true}) modal_cancelar:ElementRef;
  @ViewChild('modal_apagar', {static:true}) modal_apagar:ElementRef;
  @ViewChild('modal_encender', {static:true}) modal_encender:ElementRef;
  @ViewChild('pin_modal', {static:true}) pin_modal:ElementRef;
  @ViewChild('pin_modal_2', {static:true}) pin_modal_2:ElementRef;
  @ViewChild('pin_modal_error', {static:true}) pin_modal_error:ElementRef;
  @ViewChild('operation', {static:true}) operation:ElementRef;


  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek()
   };
  closeResult:string; //Modal close result
  cards=null;
  selectedCard:Card=null;
  selectedCardId:string=null;
  selectedDate:{startDate:any, endDate:any};
  movementsResponse:MovementsResponse=null;
  movements:Movement[]=null;
  globalResponse:GlobalResponse=null;
  balance = null;
  movementsPage=0;
  movementsPages=0;
  movementsPerPage=10;
  isLoading=false;
  operationText=null;
  errorFechas=false;
  maxDate;
  userInformation:UserInformation=null;



  constructor( public api: ApiService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private modalService: NgbModal, private spinner:NgxSpinnerService) {
    this.matIconRegistry.addSvgIcon(
      'search',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'caution-sign',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/caution-sign.svg')
    );
    this.selectedDate = {
      startDate:null, //moment('2015-11-18T00:00Z'),
      endDate:null,
    }
    this.getGlobal();
    this.getUserInformation();
  }

  ngOnInit() {
    this.maxDate = moment();
  }

  getGlobal(){
    this.spinner.show();
    this.api.getGlobal().then(data=>{
      this.spinner.hide();
      // console.log("global:", data);
      this.globalResponse = data[0];
      this.cards = this.globalResponse.cards;
      this.selectedCard=this.globalResponse.cards[0];
      this.selectedCardId=this.selectedCard.cardId;
      this.filterChanged();
      this.getBalance();
    }).catch(e=>{
      this.spinner.hide();
    });
  }

  getBalance(){
    this.spinner.show();
    let gr = this.globalResponse;
    if(gr && gr.clientServiceId && this.selectedCard){
      this.api.getBalance(gr.clientServiceId, this.selectedCard.cardId).then((d:any)=>{
        this.spinner.hide();
        // console.log("balance:", d);
        if(d && d.balance!=null){
          this.balance=d.balance;
        }else{
          this.balance=null;
        }
      }).catch(e=>{
        this.spinner.hide();
      });
    }
  }

  getUserInformation(){
    this.spinner.show();
    this.api.getUserInformation().then((data:UserInformation)=>{
      this.spinner.hide();
      // console.log("ui:",data);
      this.userInformation=data;
    }).catch(e=>{
      this.spinner.hide();
      console.error(e);
    });
  }

  pageChange(i){
    this.movementsPage=i;
    this.movements=this.movementsResponse.list.slice(this.movementsPerPage*i, (this.movementsPerPage*i)+this.movementsPerPage)
    // this.filterChanged();
  }

  filterChanged(reset=false, dateChangedEvent=null){
    if(dateChangedEvent && dateChangedEvent.target){//Clear date when returns an element
      switch(dateChangedEvent.target.id){
        case 'start_date':
          this.selectedDate.startDate.inicio=null;
          this.selectedDate.startDate.fin=null;
        break;
        case 'end_date':
          this.selectedDate.endDate.inicio=null;
          this.selectedDate.endDate.fin=null;
        break;
      }
    }
    if(this.selectedDate.startDate && this.selectedDate.startDate.inicio && this.selectedDate.endDate && this.selectedDate.endDate.inicio){
      if(this.selectedDate.startDate.inicio.isSameOrAfter(this.selectedDate.endDate.inicio)){
        this.errorFechas=true;
        return;
      }else{
        this.errorFechas=false;
      }
    }
    if(reset){
      this.movementsPage=0;
    }
    let gr = this.globalResponse;
    if(!gr || !gr.productRef || !gr.clientServiceId || !gr.id || !this.selectedCardId){
      return;
    }
    let params=null;
    let startDate=null, endDate=null;
    if(this.movementsResponse){
      params={
        maxResults:this.movementsResponse.maxResult,
        startNumber:this.movementsPage*this.movementsResponse.maxResult
      }
      if(this.selectedDate.startDate && this.selectedDate.startDate.inicio){
        startDate=this.selectedDate.startDate.inicio;
        params['fromDate'] = this.selectedDate.startDate.inicio.format('DD-MM-YYYY');
      }
      if(this.selectedDate.endDate && this.selectedDate.endDate.inicio){
        endDate=this.selectedDate.endDate.inicio;
        params['toDate'] = this.selectedDate.endDate.inicio.format('DD-MM-YYYY');
      }
    }

    this.spinner.show();
    this.api.allMovements(gr, this.selectedCardId, params).then((data:any)=>{
      this.spinner.hide();
      this.movementsResponse=data;

      this.movementsResponse.list = this.movementsResponse.list.filter((e)=>{
        let d = moment(e.dateTransaction);
        if(this.selectedDate && this.selectedDate.startDate.inicio != null && this.selectedDate.endDate.inicio != null) {
          return moment(e.dateTransaction).isBetween(this.selectedDate.startDate.inicio, this.selectedDate.endDate.inicio, 'day', "[]");
        } else if(this.selectedDate && this.selectedDate.startDate.inicio != null && this.selectedDate.endDate.inicio == null) {
          return moment(e.dateTransaction).isSameOrAfter(this.selectedDate.startDate.inicio, 'day');
        } else if(this.selectedDate && this.selectedDate.startDate.inicio == null && this.selectedDate.endDate.inicio != null) {
          return moment(e.dateTransaction).isSameOrBefore(this.selectedDate.endDate.inicio, 'day');
        } else {
          return true;
        }
      });

      this.movementsResponse.list=this.movementsResponse.list.map((e)=>{
        switch(e.typeTransaction){
          case TypeTransaction.RETURN:
            e.typeTransaction='RETURN';
            break;
          case TypeTransaction.CHARGE:
            e.typeTransaction='CHARGE';
            break;
        }
        // if(e.actualAmount){ //Patch €
        //   e.actualAmount = e.actualAmount.slice(0,e.actualAmount.length-2)+'.'+e.actualAmount.slice(e.actualAmount.length-2, e.actualAmount.length);
        // }
        return e;
      });
      this.movementsPages=Math.ceil(this.movementsResponse.list.length/this.movementsPerPage);
      this.pageChange(0);
    }).catch(e=>{
      this.spinner.hide();
    });
  }



  isInvalidDate(date) {
    return date.weekday() === 0;
  }
  isCustomDate(date) {
    return  (
      date.weekday() === 0 || date.weekday() === 6
    )  ? 'mycustomdate' : false;
  }

  pinRequestTest(){
    this.api.pinRequest(this.selectedCard.cardId, this.selectedCard.accountCardIdentificationCode, this.userInformation.mobilePhone).then(d=>{
    }).catch(e=>{
    });
  }

  ClickConsultarPin(event){
    this.modalService.open(this.pin_modal, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-primary',
        centered:true}
    ).result.then((result)=>{
        if(result==='ok' && !this.isLoading && this.userInformation){
          this.isLoading=true;
          this.spinner.show();
          this.api.pinRequest(this.selectedCard.cardId, this.selectedCard.accountCardIdentificationCode, this.userInformation.mobilePhone).then(data=>{
            this.spinner.hide();
            // console.log("spok:",data);
            this.isLoading=false;
            this.operation = undefined;
            this.ClickConsultarPin2();
          }).catch(e=>{
            this.spinner.hide();
            this.isLoading=false;
            this.modalService.open(this.pin_modal_error, {
              ariaLabelledBy:'modal-basic-title',
              backdropClass:'modal-help-backdrop',
              windowClass:'modal-primary',
              centered:true}
            );
          });
        }
    }, (reason) => {
      this.operationText = undefined;
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });;
  }
  ClickConsultarPin2(){
    this.modalService.open(this.pin_modal_2, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-primary',
        centered:true}
    ).result.then((result)=>{
        if(result==='ok'){

        }
    }, (reason) => {
      this.operationText = undefined;
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  ClickDesactivarTarjeta(event){
    this.modalService.open(this.modal_apagar, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-primary',
        centered:true}
    ).result.then((result) => {
        if(result==='ok' && !this.isLoading){
            this.isLoading=true;
            this.spinner.show();
            this.api.blockCard(this.selectedCardId).then(data=>{
              this.spinner.hide();
                this.isLoading=false;
                this.getGlobal();
            }).catch(e=>{
              this.spinner.hide();
                this.isLoading=false;
            });
        }
    }, (reason) => {
      this.operationText = undefined;
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ClickActivarTarjeta(event){
    this.modalService.open(this.modal_encender, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-primary',
        centered:true}
    ).result.then((result) => {
        if(result==='ok' && !this.isLoading){
          this.isLoading=true;
          this.spinner.show();
          this.api.unblockCard(this.selectedCardId).then(data=>{
            this.spinner.hide();
            this.isLoading=false;
            this.getGlobal();
          }).catch(e=>{
            this.spinner.hide();
            this.isLoading=false;
          });
        }
    }, (reason) => {
      this.operationText = undefined;
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ClickCancelarTarjeta(event){
    this.modalService.open(this.modal_cancelar, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-primary',
        centered:true}
    ).result.then((result) => {
        if(result==='ok' && !this.isLoading){
            this.isLoading=true;
            this.spinner.show();
            this.api.deactivateCard(this.selectedCardId).then(data=>{
              this.spinner.hide();
                this.isLoading=false;
                this.getGlobal();
            }).catch(e=>{
              this.spinner.hide();
                this.isLoading=false;
            });
        }
    }, (reason) => {
      this.operationText = undefined;
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  SelectTarjeta(e){
      this.selectedCard=e;
      this.selectedCardId=e.cardId;
      this.movementsPage=0;
      this.movementsResponse=null;
      this.movements=null;
      this.selectedDate={startDate:false, endDate:false};
      this.getBalance();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  Operacion(e){
    switch(e){
      case 'Operaciones especiales': break;
      case 'apagarTarjeta': this.ClickDesactivarTarjeta(e); break;
      case 'encenderTarjeta': this.ClickActivarTarjeta(e); break;
      case 'cancelarTarjeta': this.ClickCancelarTarjeta(e); break;
      case 'solicitarPIN': this.ClickConsultarPin(e); break;
    }
  }

}
