import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('modal_logout', {static:true}) modal_logout:ElementRef;
  // routes = [
  //   {link:'/cards', name:'Posición Global'},
  //   {link:'/faq', name:'FAQ'},
  //   {link:'/account', name:'Cuenta <div class="mat-icon-wrapper p-1"><mat-icon class="p-1 color-light align-middle align-self-center" svgIcon="profile"></mat-icon></div>'}
  // ];
  navBarOpen=false;


  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public api:ApiService, private router:Router, private modalService: NgbModal) {
    this.matIconRegistry.addSvgIcon(
      'profile',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/profile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu',
      domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/icons/menu-24px.svg')
    );
  }

  ngOnInit() {
    // DEV TEST MODAL
    // this.modalService.open(this.modal_logout, {
    //   ariaLabelledBy:'modal-basic-title',
    //   backdropClass:'modal-help-backdrop',
    //   windowClass:'modal-primary',
    //   centered:true}
    //   ).result.then((result) => {

    // }, (reason) => {
    //   // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  toggleNavbar(){
    this.navBarOpen=!this.navBarOpen;
  }

  logout(){
    this.modalService.open(this.modal_logout, {
      ariaLabelledBy:'modal-basic-title',
      backdropClass:'modal-help-backdrop',
      windowClass:'modal-primary',
      centered:true}
      ).result.then((result) => {
        if(result==='ok'){
          this.api.logout();
          this.router.navigateByUrl("/login");
        }
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
