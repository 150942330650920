import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomValidators } from '../helpers/custom-validators';
import { ApiService } from '../services/api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-page-account',
  templateUrl: './page-account.component.html',
  styleUrls: ['./page-account.component.scss']
})

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
//     const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

//     return (invalidCtrl || invalidParent);
//   }
// }

export class PageAccountComponent implements OnInit {

  sendForm=null;
  passwordForm=null;
  currentTab=0;
  isLoading=false;
  provinces=null;
  roadTypes=null;
  isErrorPassword=false;
  isErrorAccount=false;
  resultTextPassword='';
  resultTextAccount='';
  info=false;


  constructor(private formBuilder: FormBuilder, private api:ApiService, private spinner:NgxSpinnerService) {
  }

  ngOnInit() {

    this.sendForm = this.formBuilder.group({
      'idSocio':[ null ],
      'name':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'lastName1':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'lastName2':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-\' ]+$')]],
      'idNumberDoc':[ null, Validators.required],
      'email':[ null, [Validators.required, Validators.email]],
      // 'typeRoad':[ null, Validators.required],
      'address':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-.0-9\'· ]+$')]],
      'zipCode':[ null, [Validators.required, Validators.minLength(3), Validators.maxLength(10)]],
      'city':[ null, [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÖØ-öø-ÿ\-.0-9\'· ]+$')]],
      'province':[ null, Validators.required],
      'mobile':[ null, [Validators.required, Validators.minLength(6), Validators.maxLength(12),Validators.pattern('^[67][0-9]{8}$') ]]
    }, {
    });

    this.spinner.show();
    this.api.getUserInformation().then((data:Array<any>)=>{
      this.spinner.hide();
      // console.log(this.sendForm.controls);
      console.log(data);
      Object.keys(data).forEach(k=>{
        // console.log(k);
        switch(k){
          case 'idNumberDoc': this.sendForm.controls['idNumberDoc'].setValue(data[k]); break;
          case 'emailAddress': this.sendForm.controls['email'].setValue(data[k]); break;
          case 'firstName': this.sendForm.controls['name'].setValue(data[k]); break;
          case 'lastName': this.sendForm.controls['lastName1'].setValue(data[k]); break;
          case 'secondLastName': this.sendForm.controls['lastName2'].setValue(data[k]); break;
          case 'mobilePhone': this.sendForm.controls['mobile'].setValue(data[k]); break;
          case 'address': this.sendForm.controls['address'].setValue(data[k]); break;
          case 'zipCode': this.sendForm.controls['zipCode'].setValue(data[k]); break;
          case 'city': this.sendForm.controls['city'].setValue(data[k]); break;
          case 'province': this.sendForm.controls['province'].setValue(data[k]); break;
          case 'birthDate': break; // this.sendForm.controls['lastName2'].setValue(data[k]); break; //La recibimos, pero no está en el formulario
          default:
            //this.sendForm.controls[k].setValue(data[k]);
        }
      });
    }).catch(e=>{
      this.spinner.hide();
      console.error(e);
    });

    this.passwordForm = this.formBuilder.group({
      oldPassword: [null,
        Validators.compose([
          Validators.required,
          CustomValidators.Pattern(/\d/, { hasNumber: true }),
          CustomValidators.Pattern(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.Pattern(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.Pattern( /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }
        ),
          Validators.minLength(8)
        ])
      ],
      newPassword: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.Pattern(/\d/, { hasNumber: true }),
          CustomValidators.Pattern(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.Pattern(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.Pattern( /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }
        ),
          Validators.minLength(8)
        ])
      ],
        newPasswordConfirm: [null, Validators.compose([Validators.required])],
      }, {
        validator: CustomValidators.MustMatch('newPassword', 'newPasswordConfirm')
      }
    );


    this.getProvinces();
    this.getRoadTypes();

  }

  getProvinces(){
    this.provinces = [
      "ÁLAVA",
      "ALBACETE",
      "ALICANTE/ALACANT",
      "ALMERÍA",
      "ASTURIAS",
      "ÁVILA",
      "BADAJOZ",
      "BARCELONA",
      "BURGOS",
      "CÁCERES",
      "CÁDIZ",
      "CANTABRIA",
      "CASTELLÓN/CASTELLÓ",
      "CEUTA",
      "CIUDAD REAL",
      "CÓRDOBA",
      "CUENCA",
      "GIRONA",
      "LAS PALMAS",
      "GRANADA",
      "GUADALAJARA",
      "GUIPÚZCOA",
      "HUELVA",
      "HUESCA",
      "ILLES BALEARS",
      "JAÉN",
      "A CORUÑA",
      "LA RIOJA",
      "LEÓN",
      "LLEIDA",
      "LUGO",
      "MADRID",
      "MÁLAGA",
      "MELILLA",
      "MURCIA",
      "NAVARRA",
      "OURENSE",
      "PALENCIA",
      "PONTEVEDRA",
      "SALAMANCA",
      "SEGOVIA",
      "SEVILLA",
      "SORIA",
      "TARRAGONA",
      "SANTA CRUZ DE TENERIFE",
      "TERUEL",
      "TOLEDO",
      "VALENCIA/VALÉNCIA",
      "VALLADOLID",
      "VIZCAYA",
      "ZAMORA",
      "ZARAGOZA"
    ];
    /*
    this.api.getProvinces().then(d=>{
      this.provinces=d;
    }).catch(e=>{
    });
    */
  }
  getRoadTypes(){
    this.roadTypes = [
      "AVENIDA",
      "CALLE",
      "CALLEJÓN",
      "CAMINO",
      "CARRETERA",
      "GLORIETA",
      "PASAJE",
      "PASEO",
      "PLAZA",
      "POLIGONO",
      "RAMBLA",
      "RESIDENCIA",
      "RONDA",
      "TRAVESÍA",
      "URBANIZACIÓN",
      "VÍA"
    ];
    /*
    this.api.getRoadTypes().then(d=>{
      this.roadTypes=d;
    }).catch(e=>{
    });
    */
  }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('password2').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit(data:any){
      if(!this.isLoading){
        this.isLoading=true;
        this.resultTextPassword='';
        this.resultTextAccount='';
        this.isErrorAccount=false;
        data.typeRoad="-";
        console.log(data);
        this.spinner.show();
        this.api.updateUserInformation(data).then(d=>{
          this.spinner.hide();
          this.isLoading=false;
          if(d['responseCode'] == 200) {
            this.resultTextAccount="Datos guardados correctamente.";
          } else {
            this.resultTextAccount="Se ha producido un error al guardar los datos.";
          }
        }).catch(e=>{
          this.spinner.hide();
          this.isLoading=false;
          this.isErrorAccount=true;
          this.resultTextAccount=e;
        });
      }
  }

  changePassword(data:NgForm){
    if(!this.isLoading){
      this.isLoading=true;
      this.resultTextPassword='';
      this.resultTextAccount='';
      this.isErrorPassword=false;
      this.spinner.show();
      this.api.changePassword(data).then(d=>{
        this.spinner.hide();
        this.isLoading=false;
        this.resultTextPassword="Contraseña cambiada.";
        this.passwordForm.reset();
      }).catch(e=>{
        this.spinner.hide();
        this.isLoading=false;
        this.isErrorPassword=true;
        this.resultTextPassword=e;
      });
    }
  }

}
