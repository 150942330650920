import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, NgForm } from '@angular/forms';


@Component({
  selector: 'app-help-box',
  templateUrl: './help-box.component.html',
  styleUrls: ['./help-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpBoxComponent implements OnInit {


  closeResult: string;

  sendForm = new FormGroup({
    name: new FormControl(''),
    enterprise: new FormControl(''),
    email: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
  });



  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content, {
        ariaLabelledBy:'modal-basic-title',
        backdropClass:'modal-help-backdrop',
        windowClass:'modal-help',
        centered:true}
        ).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onSubmit(data:NgForm){
    console.warn(this.sendForm.value);

    this.modalService.dismissAll();
  }




}
