import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from '../services/api.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);
import moment from 'moment';
import { environment as env } from '../../environments/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
moment.locale('es');



@Component({
  selector: 'app-page-register-a-data',
  templateUrl: './page-register-a-data.component.html',
  styleUrls: ['./page-register-a-data.component.scss']
})

export class PageRegisterADataComponent implements OnInit {

  @ViewChild('modal_completed', {static:true}) modal_completed:ElementRef;

  colorTheme = 'theme-orange';
  public loginError = false;
  public loginSuccess = false;
  public recaptcha_key=env.recaptcha_key;

  sendForm:FormGroup;
  isLoading=0;
  isError=false;
  resultText='';
  info=false;
  modalConfirm;
  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek()
   };
  errorText=null;
  maxDate=moment();

  constructor(private api: ApiService, private formBuilder:FormBuilder, private router:Router, private modalService: NgbModal, private spinner:NgxSpinnerService) {

  }

  ngOnInit() {
    this.sendForm = this.formBuilder.group({
      'idNumberDoc':[null, [Validators.required, Validators.pattern('(^[0-9]{8}[a-zA-Z]$)|(^[a-zA-Z][0-9]{7}[a-zA-Z]$)')] ], //DNI o NIE
      'email':[null, [ Validators.required, Validators.email ] ],
      'mobile':[null, [Validators.required, Validators.minLength(6), Validators.maxLength(12),Validators.pattern('^[67][0-9]{8}$')] ],
      'birthDate':[null, [ Validators.required ] ],
      'code':[null, [ Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      'conditions':[null, Validators.requiredTrue ],
      'recaptcha':[null, Validators.requiredTrue ]
    });

    if(!env.production && env.form_register){
      this.sendForm.patchValue(env.form_register)
    }
  }

  resolved(e){
    this.sendForm.controls['recaptcha'].setValue(true);
  }

  birthDateFocusOut(e){
    // let m = this.sendForm.controls['birthDate'].value;
    // if(m){
    //   e.target.value = m['startDate'].format("DD/MM/YYYY");
    // }
    // setTimeout(()=>{
    //   let m:any = moment(e.target.value, "DD/MM/YYYY");
    //   // console.log("fo e",e," m:",m);
    //   console.log("valid:",m._isValid);
    //   if(!m._isValid){
    //     console.log("Not valid");
    //     this.sendForm.controls['birthDate'].setValue('1/1/3000');
    //     this.sendForm.controls['birthDate'].markAsTouched();
    //     setTimeout(()=>{
    //       console.log(this.sendForm.controls['birthDate'].value);
    //     },1000);
    //   }
    // },1000);
  }

  onSubmit(form:NgForm=null){
    if(!this.sendForm.valid){
      let f = this.sendForm.value;
      let empty=false;
      Object.keys(f).forEach(k=>{
        if(!f[k]){ empty=true; }
      });
      if(empty){
        this.errorText="Debes rellenar todos los campos";
      }else{
        this.errorText="Los datos introducidos son incorrectos";
      }
      this.sendForm.markAllAsTouched();
    }else{
      this.errorText=null;
      delete form['recaptcha'];
      if(typeof form['birthDate'] != 'string') {
        form['birthDate'] = form['birthDate'].format("DD/MM/YYYY");
      }
      // console.log("data:",form); return;
      if(!this.isLoading){
        this.isLoading++;
        this.isError=false;
        this.resultText='';
        this.spinner.show();
        this.api.register(form).then((data:any)=>{
          this.spinner.hide();
            this.isLoading--;
            console.log(data);
            if(!env.production){
                let url = 'registro/'+data.registerCode+'/contrasena';
                this.router.navigateByUrl(url);
            }else{
                // this.router.navigateByUrl('registro/correo');
                this.modalService.open(this.modal_completed, {
                    ariaLabelledBy:'modal-basic-title',
                    backdropClass:'modal-help-backdrop',
                    windowClass:'modal-secondary',
                    centered:true}
                ).result.then((result) => {
                }, (reason) => {
                    this.router.navigateByUrl('/login');
                });
            }
        }).catch(e=>{
          this.spinner.hide();
            this.isLoading--;
            this.isError=true;
            this.resultText=e;
            //console.log(<any>e);
            //window.location.href = "/cards";
            this.loginError = true;
        });
      }
    }
  }

}
