import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-page-cookies',
  templateUrl: './page-cookies.component.html',
  styleUrls: ['./page-cookies.component.scss']
})
export class PageCookiesComponent implements OnInit {

  menuHeight=116;
  footerHeight=80;
  height=500;

  @HostListener('window:resize', ['$event'])
  onResize(event){
    //event.target.innerHeight
    this.height = (window.innerHeight-this.menuHeight-this.footerHeight);
  }

  constructor() { }

  ngOnInit() {
    this.onResize({});
  }

}
